<script>
import FTMTokenValue from '@/components/core/FTMTokenValue/FTMTokenValue.vue';
import { mapGetters } from 'vuex';
import { WEIToFTM } from '@/utils/transactions.js';
import { getAmountDelegated, getPendingRewards } from '@/utils/account.js';

export default {
    name: 'OverallOverview',

    components: { FTMTokenValue },

    computed: {
        ...mapGetters(['accounts']),

        accountsBalance() {
            return this.accounts.reduce((total, account) => {
                return total + WEIToFTM(account.balance);
            }, 0);
        },

        accountsPendingRewards() {
            return this.accounts.reduce((total, account) => {
                return total + WEIToFTM(getPendingRewards(account));
            }, 0);
        },

        accountsAmountDelegated() {
            return this.accounts.reduce((total, account) => {
                return total + WEIToFTM(getAmountDelegated(account));
            }, 0);
        },

        accountsTotalBalance() {
            return this.accounts.reduce((total, account) => {
                return total + WEIToFTM(account.totalBalance);
            }, 0);
        },
    },
};
</script>

<template>
    <div class="overalloverview">
        <div class="overalloverview_item">
            <h3>
                <span class="overalloverview_label light-color">Available</span>
                <FTMTokenValue :value="accountsBalance" class="overalloverview_balance" />
            </h3>
            <div class="overalloverview_currency light-color">
                <FTMTokenValue
                    :value="accountsBalance * this.$store.state.tokenPrice"
                    with-price-currency
                    no-currency
                />
            </div>
        </div>
        <div class="overalloverview_item">
            <h3>
                <span class="overalloverview_label light-color">Pending</span>
                <FTMTokenValue :value="accountsPendingRewards" class="overalloverview_balance" />
            </h3>
            <div class="overalloverview_currency light-color">
                <FTMTokenValue
                    :value="accountsPendingRewards * this.$store.state.tokenPrice"
                    with-price-currency
                    no-currency
                />
            </div>
        </div>
        <div class="overalloverview_item">
            <h3>
                <span class="overalloverview_label light-color" >Staked</span>
                <FTMTokenValue :value="accountsAmountDelegated" class="overalloverview_balance" />
            </h3>
            <div class="overalloverview_currency light-color">
                <FTMTokenValue
                    :value="accountsAmountDelegated * this.$store.state.tokenPrice"
                    with-price-currency
                    no-currency
                />
            </div>
        </div>
        <div class="overalloverview_item">
            <h3>
                <span class="overalloverview_label light-color">Total</span>
                <FTMTokenValue :value="accountsTotalBalance" class="overalloverview_balance" />
            </h3>
            <div class="overalloverview_currency light-color">
                <FTMTokenValue
                    :value="accountsTotalBalance * this.$store.state.tokenPrice"
                    with-price-currency
                    no-currency
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '../core/variables';
.overalloverview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: var(--f-spacer-5);

    &_item {
        //line-height: 1.2;
    }

    &_label {
        font-size: 18px;
        display: block;
    }

    &_balance {
        font-weight: bold;
        //font-size: 120%;
    }

    &_currency {
        font-size: 24px;
        line-height: 1.1;
        font-weight: bold;
        //margin-top: calc(-1 * var(--f-spacer-2));
        //padding-inline-start: var(--f-spacer-1);

        .f-token-value {
            //margin-inline-start: -6px;
        }
    }

    h3 {
        font-size: 28px;
        margin: 0;
        line-height: 1.36;
    }
}
@include media-max(600px) {
    .overalloverview {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

        &_label {
            //font-size: 16px;
        }

        &_currency {
            font-size: 20px;
        }

        h3 {
            font-size: 24px;
        }
    }
}
</style>
