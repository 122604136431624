<template>
    <f-card class="account-info-box info-box f-card-double-padding">
        <div class="align-items-center align-center-lg no-collapse">
            <div class="account-info-box-balances">
                <div class="balance">
                    <h2 class="h3">
                        <span class="label h3">Available</span>
                        <span>
                            <f-t-m-token-value :value="accountBalance" convert />
                        </span>
                    </h2>
                    <div class="currency">
                        <f-t-m-token-value
                            :value="WEIToFTM(accountBalance) * tokenPrice"
                            with-price-currency
                            no-currency
                        />
                    </div>
                </div>
                <div class="balance">
                    <h2 class="h3">
                        <span class="label h3">Pending</span>
                        <span>
                            <f-t-m-token-value :value="pendingRewards" convert />
                        </span>
                    </h2>
                    <div class="currency">
                        <f-t-m-token-value
                            :value="WEIToFTM(pendingRewards) * tokenPrice"
                            with-price-currency
                            no-currency
                        />
                    </div>
                </div>
                <div class="balance">
                    <h2 class="h3">
                        <span class="label h3">Staked</span>
                        <span>
                            <f-t-m-token-value :value="amountDelegated" convert />
                        </span>
                    </h2>
                    <div class="currency">
                        <f-t-m-token-value
                            :value="WEIToFTM(amountDelegated) * tokenPrice"
                            with-price-currency
                            no-currency
                        />
                    </div>
                </div>
                <div class="balance total-balance">
                    <h2 class="h3">
                        <span class="label h3">Total</span>
                        <span>
                            <f-t-m-token-value :value="accountTotalBalance" convert />
                        </span>
                    </h2>
                    <div class="currency">
                        <f-t-m-token-value
                            :value="WEIToFTM(accountTotalBalance) * tokenPrice"
                            with-price-currency
                            no-currency
                        />
                    </div>
                </div>
            </div>
            <div class="align-center"><AccountActionsButtons /></div>
        </div>
    </f-card>
</template>

<script>
import FCard from '../core/FCard/FCard.vue';
import { mapGetters } from 'vuex';
import { WEIToFTM } from '../../utils/transactions.js';
import { pollingMixin } from '../../mixins/polling.js';
import { UPDATE_ACCOUNT_BALANCE } from '../../store/actions.type.js';
import FTMTokenValue from '@/components/core/FTMTokenValue/FTMTokenValue.vue';
import { getAmountDelegated, getPendingRewards } from '@/utils/account.js';
import AccountActionsButtons from '@/components/AccountActionsButtons/AccountActionsButtons.vue';

export default {
    components: { AccountActionsButtons, FTMTokenValue, FCard },

    mixins: [pollingMixin],

    computed: {
        ...mapGetters(['currentAccount', 'currentAccountAddress']),

        accountBalance() {
            return this.currentAccount ? this.currentAccount.balance : 0;
        },

        accountTotalBalance() {
            return this.currentAccount ? this.currentAccount.totalBalance : 0;
        },

        pendingRewards() {
            return getPendingRewards(this.currentAccount);
        },

        amountDelegated() {
            return getAmountDelegated(this.currentAccount);
        },

        tokenPrice() {
            return this.$store.state.tokenPrice;
        },
    },

    mounted() {
        this.$store.dispatch(UPDATE_ACCOUNT_BALANCE);

        this._polling.start(
            'update-account-balance',
            () => {
                this.$store.dispatch(UPDATE_ACCOUNT_BALANCE);
            },
            4500
        );
    },

    methods: {
        WEIToFTM,
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
