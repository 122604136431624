<script>
import { objectEquals } from '@/utils/index.js';
import FTMTokenValue from '@/components/core/FTMTokenValue/FTMTokenValue.vue';

export default {
    name: 'EstimatedFees',

    components: { FTMTokenValue },

    props: {
        tx: {
            type: Object,
            default() {
                return null;
            },
            required: true,
        },
        decimals: {
            type: Number,
            default: -1,
        },
    },

    data() {
        return {
            fee: 0,
        };
    },

    computed: {
        cFeePrice() {
            return this.fee * this.$store.state.tokenPrice;
        },

        cApproximately() {
            return this.decimals > -1 ? '~' : '';
        },
    },

    watch: {
        tx: {
            async handler(tx, oldTx) {
                if (!objectEquals(tx, oldTx)) {
                    this.fee = await this.$fWallet.getEstimatedFees(tx || {});
                }
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <div class="estimatedfees">
        Estimated Fee:
        <span class="estimatedfees_fee"
            >{{ cApproximately }}<FTMTokenValue v-if="fee > 0" :value="fee" :decimals="decimals" /><template v-else
                >-</template
            ></span
        >
        <span class="light-color">
            ({{ cApproximately
            }}<FTMTokenValue :value="cFeePrice" with-price-currency no-currency :decimals="decimals" />)
        </span>
    </div>
</template>

<style lang="scss">
.estimatedfees {
    &_fee {
        font-weight: bold;
        //font-size: 110%;
    }

    .f-token-value {
        margin-inline-start: -5px;
    }
}
</style>
