<script>
import AccountList from '@/components/AccountList/AccountList.vue';
import { getUniqueId } from '@/utils/index.js';
import { mapGetters } from 'vuex';
import OverallOverview from '@/components/OverallOverview/OverallOverview.vue';
import FCard from '@/components/core/FCard/FCard.vue';

export default {
    name: 'Overview',

    components: { FCard, OverallOverview, AccountList },

    data() {
        return {
            walletsId: getUniqueId(),
            overviewId: getUniqueId(),
        };
    },

    computed: {
        ...mapGetters(['accounts']),
    },
};
</script>

<template>
    <div class="overview std-view">
        <main class="main">
            <h1>Overview</h1>
            <section :aria-labelledby="overviewId" class="overview_overallsection">
                <h2 :id="overviewId" class="not-visible">Overall Overview</h2>
                <FCard>
                    <OverallOverview />
                </FCard>
            </section>
            <section :aria-labelledby="walletsId">
                <h2 :id="walletsId">
                    Wallets <span class="f-records-count">({{ accounts.length }})</span>
                </h2>
                <AccountList overview-mode edit-mode />
            </section>
        </main>
    </div>
</template>

<style lang="scss">
.overview {
    &_overallsection {
        margin-bottom: var(--f-spacer-5);
    }

    section {
        > h2 {
            margin-bottom: 0;
        }
    }
}
</style>
