<script>
import mmIcon from '../../assets/svg/metamask.svg';
import ledgerIcon from '../../assets/svg/ledger-logo-icon.svg';
import coinbaseWalletIcon from '../../assets/svg/coinbase-wallet.svg';
import walletConnectWalletIcon from '../../assets/svg/walletconnect-circle-blue.svg';
import keystoreWalletIcon from '../../assets/svg/software-wallet.svg';
import watchWalletIcon from '../../assets/svg/eye.svg';

export default {
    name: 'WalletIcon',

    props: {
        walletCode: {
            type: String,
            default: '',
            required: true,
        },
        size: {
            type: [String, Number],
            default: 32,
        },
    },

    computed: {
        icon() {
            let icon = null;
            const { walletCode } = this;

            if (walletCode === 'keystore') {
                icon = keystoreWalletIcon;
            } else if (walletCode === 'metamask' || walletCode === 'mm') {
                icon = mmIcon;
            } else if (walletCode === 'ledger' || walletCode === 'ledgerEth') {
                icon = ledgerIcon;
            } else if (walletCode === 'coinbase') {
                icon = coinbaseWalletIcon;
            } else if (walletCode === 'walletConnect') {
                icon = walletConnectWalletIcon;
            } else if (walletCode === 'watch') {
                icon = watchWalletIcon;
            }

            return icon;
        },

        original() {
            const { walletCode } = this;

            return (
                walletCode === 'metamask' ||
                walletCode === 'mm' ||
                walletCode === 'coinbase' ||
                walletCode === 'walletConnect'
            );
        },

        fill() {
            const { walletCode } = this;

            return (
                walletCode === 'keystore' ||
                walletCode === 'ledger' ||
                walletCode === 'ledgerEth' ||
                walletCode === 'watch'
            );
        },
    },
};
</script>

<template>
    <icon
        :data="icon"
        :width="size.toString()"
        :height="size.toString()"
        :original="original"
        :fill="fill"
        class="walleticon"
    />
</template>
