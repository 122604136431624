<script>
import FWindow from '../../core/FWindow/FWindow.vue';
import WatchAccountForm from '../../forms/WatchAccountForm.vue';

export default {
    name: 'WatchAccountWindow',

    components: { WatchAccountForm, FWindow },

    methods: {
        show() {
            this.$refs.win.show();
        },

        /**
         * Called when `WatchAccountForm` is submited.
         */
        onWatchAccountFormData() {
            this.$refs.win.hide('fade-leave-active');
        },

        /**
         * Re-target `'window-hide'` event.
         *
         * @param {object} _data
         */
        onWindowHide(_data) {
            this.$emit('window-hide', _data);
        },
    },
};
</script>

<template>
    <div class="watch-account-window">
        <FWindow
            ref="win"
            modal
            style="max-width: 600px"
            title="Watch Wallet"
            class="double-body-padding watch-account-window"
            animation-in="scale-center-enter-active"
            animation-out="scale-center-leave-active"
            @window-hide="onWindowHide"
        >
            <p class="align-center">Add a wallet address to watch</p>
            <WatchAccountForm @watch-account-form-data="onWatchAccountFormData" @window-hide="onWindowHide" />
        </FWindow>
    </div>
</template>

<style lang="scss">
.watch-account-window {
    .watch-account-form {
        margin-top: var(--f-spacer-4);
    }
}
</style>
