<script>
import FWindow from '@/components/core/FWindow/FWindow.vue';
import WalletActions from '@/components/WalletActions/WalletActions.vue';

export default {
    name: 'WalletActionsPopover',

    components: { WalletActions, FWindow },

    methods: {
        show() {
            this.$refs.popover.show();
        },

        hide() {
            this.$refs.popover.hide();
        },
    },
};
</script>

<template>
    <FWindow
        ref="popover"
        popover
        attach-position="rt rb"
        no-title
        no-controls
        hide-on-window-mousedown
        :attach-margin="[0, 0, 0, 0]"
        :prevent-focus="false"
        :z-index="9"
        animation-in="scale-center-enter-active"
        animation-out="scale-center-leave-active"
        class="walletactionspopover light popover-menu"
        v-bind="$attrs"
    >
        <slot>
            <WalletActions @window-hide="$emit('window-hide', $event)" />
        </slot>
    </FWindow>
</template>

<style lang="scss">
.walletactionspopover {
    --f-window-popover-body-padding: 0 !important;

    width: 100%;
    max-width: 260px;
}
</style>
