<script>
import { mapState } from 'vuex';

export default {
    name: 'AccountActive',

    props: {
        address: {
            type: String,
            default: '',
            required: true,
        },
    },
    computed: {
        ...mapState('mm', {
            mmAccount: 'account',
        }),

        ...mapState('walletConnect', {
            walletConnectAccount: 'account',
        }),

        isActive() {
            const address = this.address;

            return (
                this.$fWallet.sameAddresses(address, this.mmAccount) ||
                this.$fWallet.sameAddresses(address, this.$walletlink.selectedAddress) ||
                this.$fWallet.sameAddresses(address, this.walletConnectAccount)
            );
        },
    },
};
</script>

<template>
    <span class="accountactive" :class="{ 'accountactive-active': isActive }"></span>
</template>

<style lang="scss">
.accountactive {
    display: none;
    position: absolute;
    top: 6px;
    left: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--f-success-color);

    &-active {
        display: inline-block;
    }
}
</style>
