<template>
    <div class="view-gov-home">
        <h1 data-focus>Governance</h1>

        <f-tabs>
            <template #currentproposals>
                Current Proposals
                <span class="f-records-count">({{ currentProposalsRecordsCount }})</span>
            </template>
            <template #pastproposals>
                Past Proposals
                <span class="f-records-count">({{ pastProposalsRecordsCount }})</span>
            </template>

            <f-tab title-slot="currentproposals">
                <h2 class="not-visible">Current Proposals {{ currentProposalsRecordsCount }} items</h2>
                <gov-proposal-list active-only @records-count="currentProposalsRecordsCount = $event" />
            </f-tab>
            <f-tab title-slot="pastproposals">
                <h2 class="not-visible">Past Proposals {{ pastProposalsRecordsCount }} items</h2>
                <gov-proposal-list @records-count="pastProposalsRecordsCount = $event" />
            </f-tab>
        </f-tabs>
    </div>
</template>

<script>
import GovProposalList from '@/components/data-tables/GovProposalList/GovProposalList.vue';
import { focusElem } from '@/utils/aria.js';
import FTab from '@/components/core/FTabs/FTab.vue';
import FTabs from '@/components/core/FTabs/FTabs.vue';

export default {
    name: 'GovHome',

    components: {
        FTabs,
        FTab,
        GovProposalList,
    },

    data() {
        return {
            currentProposalsRecordsCount: 0,
            pastProposalsRecordsCount: 0,
        };
    },

    mounted() {
        focusElem(this.$el);
    },
};
</script>
