<script>
/* eslint-disable */
import FButton from '@/components/core/FButton/FButton.vue';
import FAddress from '@/components/core/FAddress/FAddress.vue';
import { getJazzicon } from '@/plugins/jazzicon/jazzicon.js';
import FEllipsis from '@/components/core/FEllipsis/FEllipsis.vue';
import WalletIcon from '@/components/WalletIcon/WalletIcon.vue';

export default {
    name: 'WalletButton',

    components: { WalletIcon, FEllipsis, FAddress, FButton },

    props: {
        address: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        subText: {
            type: String,
            default: '',
        },
        placeholderText: {
            type: String,
            default: '',
        },
    },

    computed: {
        cName() {
            const name = this.name;

            return name && name.trim().indexOf('0x') === -1 ? name : '';
        },
    },

    methods: {
        getJazzicon,
    },
};
</script>

<template>
    <FButton
        class="btn secondary secondary-light large break-word walletbutton"
        :aria-label="`${$t('wallet.walletButton.activeAccount')} ${address}`"
    >
        <template v-if="address">
            <div class="walletbutton_box">
                <div
                    class="walletbutton_jazzicon"
                    aria-hidden="true"
                    data-testid="jazzicon"
                    v-html="getJazzicon(address, 32)"
                ></div>
                <div class="walletbutton_box_col2">
                    <FEllipsis v-if="cName" :text="cName" class="walletbutton_name" :title="cName" />
                    <FAddress :address="address" :class="{ 'light-color': !!cName }">
                        <template #suffix>
                            <WalletIcon v-if="type" :wallet-code="type" size="14" />
                        </template>
                    </FAddress>
                </div>
            </div>
        </template>
        <template v-else>
            {{ placeholderText }}
        </template>
    </FButton>
</template>

<style lang="scss">
.walletbutton {
    //width: 220px;
    padding: var(--f-spacer-1) var(--f-spacer-3) !important;

    &_box {
        display: flex;
        align-items: center;
        gap: var(--f-spacer-2);

        &_col2 {
            flex: 3;
            text-align: start;

            > .f-ellipsis {
                max-width: 180px;
            }
        }
    }

    &_jazzicon {
        & > div:first-child {
            border-radius: 50%;
            display: block !important;
        }
    }

    .faddress {
        max-width: 164px;

        &.light-color {
            font-size: 90%;
        }

        &.f-ellipsis {
            align-items: initial;

            span:last-child {
                padding-inline-start: var(--f-spacer-2);
            }
        }

        .left-chars,
        .right-chars {
            //margin-top: calc(var(--f-spacer-3) * -1);
        }
    }

    .walleticon {
        opacity: 0.8;
    }
}

:root.dark-theme {
    .walletbutton {
        color: #fff !important;
    }
}
</style>
