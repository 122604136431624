<template>
    <div class="f-transaction-status" :class="cStatus">
        <template v-if="useIcons">
            <icon :data="getStatusIcon(status)" width="20" height="20" :title="cStatus" />
        </template>
        <template v-else>
            {{ $t(cStatus) }}
        </template>
    </div>
</template>

<script>
import circleCheck from '@/assets/svg/circle-check.svg';
import circleX from '@/assets/svg/circle-x.svg';
import clock from '@/assets/svg/clock.svg';

/**
 * Component for displaying transaction status.
 */
export default {
    props: {
        /**
         * 0 - error
         * 1 - success
         * 2 - pending
         */
        status: {
            type: Number,
            default: 1,
        },
        useIcons: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        /**
         * String representation of status.
         *
         * @return {'success' | 'error' | 'pending'}
         */
        cStatus() {
            let status = 'pending';

            switch (this.status) {
                case 0:
                    status = 'error';
                    break;
                case 1:
                    status = 'success';
                    break;
            }

            return status;
        },
    },

    methods: {
        getStatusIcon(status) {
            let icon = clock;

            if (status === 0) {
                icon = circleX;
            } else if (status === 1) {
                icon = circleCheck;
            }

            return icon;
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
