import { toBigNumber, toHex } from '@/utils/big-number.js';

export function getPendingRewards(account) {
    const pendingRewards = account?.pendingRewards || [];

    if (pendingRewards.length > 0) {
        let bn = toBigNumber(0);

        pendingRewards.forEach((pr) => {
            bn = bn.plus(pr);
        });

        return toHex(bn);
    }

    return '0x0';
}

export function getAmountDelegated(account) {
    const amountDelegated = account?.amountDelegated || [];

    if (amountDelegated.length > 0) {
        let bn = toBigNumber(0);

        amountDelegated.forEach((pr) => {
            bn = bn.plus(pr || '0x0');
        });

        return toHex(bn);
    }

    return '0x0';
}
