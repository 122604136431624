<template>
    <span class="f-back-button">
        <slot>
            <RouterLink
                v-if="isLink"
                :to="{ name: routeName, params }"
                class="btn light large same-size round"
                title="Back"
                aria-label="Go to previous page"
            >
                <icon data="@/assets/svg/arrow-left.svg" aria-hidden="true" class="rtl-mirror" />
            </RouterLink>
            <button
                v-else
                class="btn light large same-size round"
                title="Back"
                aria-label="Go to previous page"
                @click="onClick"
            >
                <icon data="@/assets/svg/arrow-left.svg" aria-hidden="true" class="rtl-mirror" />
            </button>
        </slot>
    </span>
</template>

<script>
export default {
    name: 'FBackButton',

    props: {
        routeName: {
            type: String,
            default: '',
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    methods: {
        onClick() {
            this.goBack();
        },

        goBack() {
            if (this.routeName) {
                this.$router.replace({ name: this.routeName, params: this.params });
            }
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
