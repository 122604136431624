<template>
    <div class="settingslinks">
        <f-card class="f-card-double-padding">
            <div class="small-container">
                <!--
                <p>
                    <a href="https://fantom.foundation/how-to-use-fantom-wallet/" rel="noopener" target="_blank">
                        How to use Fantom PWA Wallet
                    </a>
                </p>
-->

                <p style="padding-bottom: 0">
                    <a :href="explorerUrl" rel="noopener" target="_blank"> Fantom Explorer </a>
                </p>
            </div>
        </f-card>
    </div>
</template>

<script>
import FCard from '@/components/core/FCard/FCard.vue';
import appConfig from '../../../app.config.js';

export default {
    name: 'SettingsLinks',

    components: { FCard },

    data() {
        return {
            explorerUrl: appConfig.explorerUrl,
        };
    },
};
</script>
