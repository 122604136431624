<script>
import WalletAccountList from '@/components/WalletAccountList/WalletAccountList.vue';
import { mapGetters } from 'vuex';
import FButton from '@/components/core/FButton/FButton.vue';
import WalletActionsPopover from '@/components/WalletActionsPopover/WalletActionsPopover.vue';

export default {
    name: 'WalletButtonPage',

    components: { WalletActionsPopover, FButton, WalletAccountList },

    computed: {
        ...mapGetters(['accounts', 'currentAccount']),
    },

    methods: {
        onAddWalletClick() {
            this.$refs.walletActionsPopover.show();
        },

        onWindowHide() {
            this.$emit('hide-popover');
        },
    },
};
</script>

<template>
    <div class="walletbuttonpage">
        <div class="walletbuttonpage_section">
            <h3 class="walletbuttonpage_connectedlabel">
                Connected
                <FButton id="wallet-actions-btn" class="btn small walletbuttonpage_addwalletbtn" @click.native="onAddWalletClick">
                    <icon data="@/assets/svg/plus.svg" width="12" height="12" aria-hidden="true" /> Add Wallet
                </FButton>
            </h3>
            <WalletAccountList :accounts="accounts" />
        </div>
        <div
            class="walletbuttonpage_section walletbuttonpage_section-withdivider walletbuttonpage_section-nopadding walletbuttonpage_links"
        >
            <ul class="no-markers">
                <li>
                    <RouterLink :to="{ name: 'overview' }" class="btn menu-btn light">
                        <icon data="@/assets/svg/ul-list.svg" width="20" height="20" /> Overview
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'contacts' }" class="btn menu-btn light">
                        <icon data="@/assets/svg/address-book-regular.svg" width="20" height="20" /> Contacts
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'settings' }" class="btn menu-btn light">
                        <icon data="@/assets/svg/monochrome/Sidebar/Settings.svg" width="20" height="20" /> Settings
                    </RouterLink>
                </li>
            </ul>
        </div>

        <WalletActionsPopover ref="walletActionsPopover" attach-to="#wallet-actions-btn" @window-hide="onWindowHide" />
    </div>
</template>

<style lang="scss">
.walletbuttonpage {
    &_section {
        padding: var(--f-spacer-3);
        //padding-bottom: var(--f-spacer-4);

        & + .walletbuttonpage_section {
            padding-top: 0;
            //margin-top: var(--f-spacer-3);
        }

        &-withdivider {
            border-top: var(--f-window-popover-border);
        }

        &-nopadding {
            padding: 0;
        }
    }

    &_links {
        /*
        display: flex;

        > * {
            flex: 1;
        }
        */
    }

    &_connectedlabel {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_addwalletbtn {
        svg {
            margin-inline-end: var(--f-spacer-1) !important;
            vertical-align: initial;
            position: relative;
            top: 1px;
        }
    }

    h3 {
        margin-bottom: var(--f-spacer-3);
    }
}
</style>
