<script>
import FMessage from '@/components/core/FMessage/FMessage.vue';
import FViewTransition from '@/components/core/FViewTransition/FViewTransition.vue';
import { eventBusMixin } from '@/mixins/event-bus.js';
import { mapGetters } from 'vuex';
import { appStructureTree } from '@/app-structure.js';
import { focusElem } from '@/utils/aria.js';
import {
    DEACTIVATE_ACTIVE_ACCOUNT,
    SET_ACTIVE_ACCOUNT_ADDRESS,
    SET_ACTIVE_ACCOUNT_BY_ADDRESS,
} from '@/store/mutations.type.js';

export default {
    name: 'Receive',

    components: { FViewTransition, FMessage },

    mixins: [eventBusMixin],

    computed: {
        ...mapGetters(['currentAccount']),

        viewsStructure() {
            const accountNode = appStructureTree.serialize(appStructureTree.get('account'));

            return accountNode ? [JSON.parse(accountNode)] : [];
        },
    },

    watch: {
        $route(_value) {
            const { address } = _value.params;

            if (address && address.toLowerCase() !== this.currentAccount.address.toLowerCase()) {
                this.setActiveAccount(address);
                this._eventBus.emit('account-picked', address);
            }
        },
    },

    created() {
        this.setActiveAccount(this.$route.params.address);
    },

    mounted() {
        focusElem(this.$el);
    },

    methods: {
        /**
         * @param {string} _address
         * @param {string} _accountType
         */
        setActiveAccount(_address, _accountType) {
            if (_address) {
                this.$store.commit(DEACTIVATE_ACTIVE_ACCOUNT);
                this.$store.commit(SET_ACTIVE_ACCOUNT_BY_ADDRESS, { address: _address, accountType: _accountType });
                this.$store.commit(SET_ACTIVE_ACCOUNT_ADDRESS, _address);
            } else {
                const address = this.currentAccount?.address || '';

                if (address) {
                    this.$router.replace({ name: 'account-history', params: { address } });
                }
            }
        },
    },
};
</script>

<template>
    <div class="view-receive std-view">
        <template v-if="!currentAccount">
            <FMessage type="error" alert with-icon>Bad wallet</FMessage>
        </template>
        <template v-else>
            <main class="main">
                <FViewTransition watch-route :views-structure="viewsStructure">
                    <router-view></router-view>
                </FViewTransition>
            </main>
        </template>
    </div>
</template>

<style lang="scss">
.view-receive {
    h1 {
        margin-bottom: 30px;

        &.with-back-btn {
            padding-left: 35px;

            .f-back-button {
                top: 0;
            }
        }
    }
}
</style>
