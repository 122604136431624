<template>
    <div class="account-actions-buttons">
        <router-link
            :to="{ name: 'account-send-transaction-form' }"
            class="btn large"
            title="Send"
            aria-label="Send FTM"
        >
            <icon data="@/assets/svg/send.svg" width="20" height="20" aria-hidden="true" />
            Send
        </router-link>
        <router-link
            :to="{ name: 'account-receive' }"
            class="btn large secondary"
            title="Receive"
            aria-label="Receive FTM"
        >
            <icon data="@/assets/svg/receive.svg" width="20" height="20" aria-hidden="true" />
            Receive
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'AccountActionsButtons',
};
</script>

<style lang="scss">
.account-actions-buttons {
    padding-top: 30px;
}
</style>
