<template>
    <div class="address-picker">
        <section v-if="blockchain === 'fantom'">
            <h2>Wallets</h2>
            <account-list pick-mode @account-picked="onAddressPicked" />
        </section>
        <section v-if="contactsLen > 0">
            <h2>Contacts</h2>
            <contact-list
                pick-mode
                :filter-by-blockchain="blockchain"
                @contact-picked="onAddressPicked"
                @contacts-length="onContactsLength"
            />
        </section>
    </div>
</template>

<script>
import AccountList from '../AccountList/AccountList.vue';
import ContactList from '../ContactList/ContactList.vue';

export default {
    name: 'AddressPicker',

    components: { ContactList, AccountList },

    props: {
        /** @type {WalletBlockchain} */
        blockchain: {
            type: String,
            default: 'fantom',
            validator: function (_value) {
                return ['fantom', 'ethereum', 'binance'].indexOf(_value) !== -1;
            },
        },
    },

    data() {
        return {
            contactsLen: 1,
        };
    },

    methods: {
        onAddressPicked(_address, _type) {
            this.$emit('address-picked', _address, _type);
        },

        onContactsLength(len) {
            console.log(len);
            this.contactsLen = len;
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
