<script>
import FButton from '@/components/core/FButton/FButton.vue';
import { getUniqueId } from '@/utils/index.js';

export default {
    name: 'FActionButtons',
    components: { FButton },

    props: {
        buttons: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        btnClass: {
            type: String,
            default: '',
        },
    },

    computed: {
        cButtons() {
            return this.buttons.map((button) => {
                return { _id: getUniqueId(), ...button };
            });
        },
    },

    methods: {
        onButtonClick(action) {
            if (!this.disabled) {
                this.$emit('button-action', action);
            }
        },
    },
};
</script>

<template>
    <div class="factionbuttons">
        <FButton
            v-for="button in cButtons"
            :key="button._id"
            v-bind="{ ...button, _id: null, action: null }"
            :disabled="disabled"
            :class="`btn ${btnClass}`"
            @click.native="onButtonClick(button.action)"
        />
    </div>
</template>

<style lang="scss">
.factionbuttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
