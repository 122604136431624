<script>
import FAddress from '@/components/core/FAddress/FAddress.vue';
import FTMTokenValue from '@/components/core/FTMTokenValue/FTMTokenValue.vue';
import FButton from '@/components/core/FButton/FButton.vue';
import AccountActionsPopover from '@/components/AccountActionsPopover/AccountActionsPopover.vue';
import { getUniqueId } from '@/utils/index.js';
import AccountActive from '@/components/AccountActive/AccountActive.vue';

export default {
    name: 'WalletAccountListItem',

    components: { AccountActive, AccountActionsPopover, FButton, FTMTokenValue, FAddress },

    props: {
        account: {
            type: Object,
            default() {
                return {};
            },
            required: true,
        },
        routeName: {
            type: String,
            default: '',
            required: true,
        },
        routeParams: {
            type: Object,
            default() {
                return {};
            },
            required: true,
        },
        isActiveAccount: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            accountActionsButton: getUniqueId(),
        };
    },

    methods: {
        getAccountTypeName(account) {
            return this.$fWallet.getAccountTypeName(account);
        },

        onAccountActionsClick() {
            this.$refs.accountActionsPopover.show();
        },
    },
};
</script>

<template>
    <div class="walletaccountlistitem" :class="{ walletaccountlistitem_active: isActiveAccount }">
        <div class="walletaccountlistitem_column">
            <RouterLink
                :to="{
                    name: routeName,
                    params: { ...routeParams, address: account.address },
                }"
                class="walletaccountlistitem_link"
            >
                <FAddress :address="account.address" :name="account.name" use-jazzicon :image-size="20" />
            </RouterLink>
            <div>
                <span class="walletaccountlistitem_accounttype light-color">
                    {{ getAccountTypeName(account) }}
                    <AccountActive :address="account.address" />
                </span>
            </div>
        </div>
        <div class="walletaccountlistitem_column walletaccountlistitem_balances">
            <FTMTokenValue :value="account.balance" convert _no-currency />
            <FTMTokenValue :value="account.totalBalance" convert _no-currency />
        </div>
        <div class="walletaccountlistitem_column walletaccountlistitem_menu">
            <FButton
                :id="accountActionsButton"
                class="btn light same-size round walletaccountlistitem_elevate"
                title="Account Actions"
                @click.native="onAccountActionsClick"
            >
                <icon data="@/assets/svg/ellipsis-v.svg" width="20" height="20" aria-hidden="true" />
            </FButton>
        </div>
        <AccountActionsPopover ref="accountActionsPopover" :account="account" :attach-to="`#${accountActionsButton}`" />
    </div>
</template>

<style lang="scss">
.walletaccountlistitem {
    --walletaccountlistitem-background: #f4f4f4;
    --walletaccountlistitem-active-background: #ccddff;

    position: relative;
    display: flex;
    background: var(--walletaccountlistitem-background);
    padding: var(--f-spacer-2) var(--f-spacer-3);
    border-radius: var(--f-border-radius-2);
    line-height: 1.13;

    &:hover,
    &_active {
        background: var(--walletaccountlistitem-active-background);
    }

    &_link {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            //background: red;
            border-radius: var(--f-border-radius-2);
        }
    }

    &_elevate {
        position: relative;
        z-index: 2;
    }

    &_column {
        display: flex;
        flex-direction: column;
    }

    &_balances {
        flex: 2;
        text-align: end;
    }

    &_menu {
        justify-content: center;
        padding-inline-start: var(--f-spacer-2);
        //padding-inline-end: var(--f-spacer-2);
    }

    &_accounttype {
        padding-inline-start: calc(var(--f-spacer-5) + 2px);
        font-size: 90%;
        //opacity: 0.7;
    }

    a,
    a:visited,
    a:active,
    a:hover {
        color: var(--f-doc-color) !important;
        text-decoration: none;
    }

    .faddress {
        width: 140px;
        font-weight: bold;
    }

    .f-copy-button {
        margin-top: calc(-1 * var(--f-spacer-1));
        margin-left: calc(-1 * var(--f-spacer-1));
    }

    .accountactive {
        position: initial;
        opacity: 0.6;
        width: 8px;
        height: 8px;
    }
}

:root.dark-theme {
    .walletaccountlistitem {
        --walletaccountlistitem-background: var(--f-darker-color-2);
        --walletaccountlistitem-active-background: var(--f-darker-color-3);
        //--walletaccountlistitem-active-background: var(--f-darker-color-3);
    }
}
</style>
