<script>
import ContactList from '@/components/ContactList/ContactList.vue';

export default {
    name: 'Contacts',
    components: { ContactList },
};
</script>

<template>
    <div class="view-settings std-view">
        <main class="main">
            <h1>Contacts</h1>
            <ContactList edit-mode />
        </main>
        <Footer />
    </div>
</template>

<style scoped lang="scss"></style>
