<template>
    <div class="view-wallet">
        <wallet-menu use-bottom-menu />

        <div class="narrow-container">
            <WalletHeader />
            <FViewTransition watch-route :views-structure="viewsStructure">
                <router-view></router-view>
            </FViewTransition>
        </div>
    </div>
</template>

<script>
import WalletMenu from '../../components/WalletMenu/WalletMenu.vue';
import WalletHeader from '@/components/WalletHeader/WalletHeader.vue';
import FViewTransition from '@/components/core/FViewTransition/FViewTransition.vue';
import { appStructureTree } from '@/app-structure.js';

export default {
    name: 'Wallet',

    components: { FViewTransition, WalletHeader, WalletMenu },

    computed: {
        viewsStructure() {
            let accountNode = appStructureTree.serialize(appStructureTree.get('wallet'));

            const an = JSON.parse(accountNode);
            an._c = [an._c[0]];
            accountNode = appStructureTree.serialize(an);

            return accountNode ? [JSON.parse(accountNode)] : [];
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
