<template>
    <div class="delegations-info">
        <h1 data-focus="staking">Staking</h1>

        <h2 class="dt-heading">
            Wallet Delegations
            <span class="f-records-count">({{ delegationsRecordsCount }})</span>
        </h2>

        <f-card class="f-data-layout">
            <delegation-list
                :account-address="currentAccount.address"
                @row-action="onDelegationsRowAction"
                @claim-rewards="onClaimRewards"
                @records-count="onDelegationsRecordsCount"
                @all-records-loaded="onDelegationsRecordsLoaded"
            />
            <div class="add-delegation-cont">
                <button class="btn large" type="button" @click="onAddDelegationBtnClick">
                    <icon data="@/assets/svg/plus.svg" width="16" height="16" aria-hidden="true" /> Add
                    delegation
                </button>
            </div>
        </f-card>

        <tx-confirmation-window
            ref="confirmationWindow"
            body-min-height="350px"
            window-class="send-transaction-form-tx-window"
            :window-title="windowTitle"
            :steps-count="1"
            :active-step="1"
            @cancel-button-click="onCancelButtonClick"
        />
    </div>
</template>

<script>
import FCard from '@/components/core/FCard/FCard.vue';
import DelegationList from '@/components/data-tables/DelegationList/DelegationList.vue';
import { mapGetters } from 'vuex';
import {
    DEACTIVATE_ACTIVE_ACCOUNT,
    SET_ACTIVE_ACCOUNT_ADDRESS,
    SET_ACTIVE_ACCOUNT_BY_ADDRESS,
} from '@/store/mutations.type.js';
import { focusElem } from '@/utils/aria.js';
import TxConfirmationWindow from '@/components/windows/TxConfirmationWindow/TxConfirmationWindow.vue';

export default {
    name: 'DelegationsInfo',

    components: { TxConfirmationWindow, DelegationList, FCard },

    data() {
        return {
            delegationsRecordsCount: 0,
            loadAllDelegations: false,
            reStake: false,
            windowTitle: '',
        };
    },

    computed: {
        ...mapGetters(['currentAccount']),
    },

    mounted() {
        focusElem(null, '[data-focus="staking"]');
    },

    methods: {
        /**
         * @param {string} _address
         * @param {string} _accountType
         */
        setActiveAccount(_address, _accountType) {
            this.$store.commit(DEACTIVATE_ACTIVE_ACCOUNT);
            this.$store.commit(SET_ACTIVE_ACCOUNT_BY_ADDRESS, { address: _address, accountType: _accountType });
            this.$store.commit(SET_ACTIVE_ACCOUNT_ADDRESS, _address);
        },

        showConfirmationWindow(_compName, _data, _title = '') {
            this.windowTitle = _title;
            this.$refs.confirmationWindow.changeComponent(_compName, _data);
            this.$refs.confirmationWindow.show();
        },

        onAddDelegationBtnClick() {
            this.$router.push({
                name: 'staking-stake-form',
                params: {
                    increaseDelegation: false,
                    stakerInfo: {
                        stakerInfo: {
                            name: 'Unknown',
                        },
                    },
                    previousComponent: 'delegations-info',
                },
            });
        },

        /**
         * @param {object} _item
         */
        onDelegationsRowAction(_item) {
            this.$router.push({
                name: 'staking-info',
                params: {
                    stakerId: _item.delegation.toStakerId,
                },
            });
        },

        onDelegationsRecordsCount(_count) {
            this.delegationsRecordsCount = _count;
        },

        onDelegationsRecordsLoaded() {
            this.loadAllDelegations = true;
        },

        onClaimRewards(_data) {
            this.showConfirmationWindow(
                'claim-rewards-confirmation',
                {
                    stakerId: _data.delegation.toStakerId,
                    reStake: _data.reStake,
                    fromDelegationList: _data.fromDelegationList,
                },
                _data.reStake ? 'Claim & Restake' : 'Claim Rewards'
            );

            this.reStake = _data.reStake;
        },

        onCancelButtonClick(cancelBtnClicked) {
            if (!cancelBtnClicked && this.reStake) {
                this.$emit('reload-view');
            }
        },
    },
};
</script>

<style lang="scss">
@import 'style';
</style>
