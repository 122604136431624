<script>
import WalletAccountListItem from '@/components/WalletAccountListItem/WalletAccountListItem.vue';
import { UPDATE_ACCOUNTS_BALANCES } from '@/store/actions.type.js';
import { pollingMixin } from '@/mixins/polling.js';
import { mapGetters } from 'vuex';

export default {
    name: 'WalletAccountList',

    components: { WalletAccountListItem },

    mixins: [pollingMixin],

    props: {
        accounts: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            routeName: 'account-history',
            routeParams: {},
        };
    },

    computed: {
        ...mapGetters(['currentAccount']),
    },

    /*
    created() {
        const routeName = this.$route.name;

        if (
            routeName.indexOf('account-') > -1 ||
            routeName.indexOf('defi-') > -1 ||
            routeName.indexOf('fmint') > -1 ||
            routeName.indexOf('fswap') > -1 ||
            routeName.indexOf('staking') > -1 ||
            routeName.indexOf('funiswap') > -1 ||
            routeName.indexOf('gov-') > -1
        ) {
            this.routeName = routeName;
            this.routeParams = this.$route.params;
        }
    },
*/

    mounted() {
        this.$store.dispatch(UPDATE_ACCOUNTS_BALANCES);

        this._polling.start(
            'wallet-account-list',
            () => {
                this.$store.dispatch(UPDATE_ACCOUNTS_BALANCES);
            },
            7000
        );
    },

    methods: {
        isActiveAccount(account) {
            return this.$fWallet.sameAddresses(account.address, this.currentAccount?.address || '');
        },
    },
};
</script>

<template>
    <div class="walletaccountlist">
        <ul v-if="accounts.length > 0" class="no-markers">
            <li v-for="account in accounts" :key="account.address">
                <WalletAccountListItem
                    :account="account"
                    :is-active-account="isActiveAccount(account)"
                    :route-name="routeName"
                    :route-params="routeParams"
                />
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
.walletaccountlist {
    ul {
        overflow-y: auto;
        max-height: 184px;
        //max-height: 208px;

        li + li {
            margin-top: var(--f-spacer-2);
        }
    }
}
</style>
