<script>
/* eslint-disable */
import FEllipsis from '@/components/core/FEllipsis/FEllipsis.vue';
import FCopyButton from '@/components/core/FCopyButton/FCopyButton.vue';
import FImage from '@/components/core/FImage/FImage.vue';
import { getJazzicon } from '@/plugins/jazzicon/jazzicon.js';

export default {
    name: 'FAddress',

    components: { FImage, FCopyButton, FEllipsis },

    props: {
        address: {
            type: String,
            default: '',
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        useJazzicon: {
            type: Boolean,
            default: false,
        },
        useCopyButton: {
            type: Boolean,
            default: false,
        },
        imageSize: {
            type: Number,
            default: 24,
        },
    },

    computed: {
        cName() {
            const name = this.name;

            return name && name.trim().indexOf('0x') === -1 ? name : '';
        },
    },

    mounted() {
        if (this.$el?.setProperty) {
            this.$el?.style.setProperty('--faddress-image-size', `${this.imageSize}px`);
        }
    },

    methods: {
        getJazzicon,
    },
};
</script>

<template>
    <FEllipsis ref="el" :text="cName || address" :overflow="cName ? 'end' : 'middle'" class="faddress">
        <template #prefix>
            <slot name="prefix"></slot>
            <FImage
                v-if="image"
                :src="image"
                fit="cover"
                :size="`${imageSize}px`"
                class="faddress_image"
                :alt="address"
                aria-hidden="true"
            />
            <template v-else-if="useJazzicon">
                <div
                    class="faddress_image faddress_jazzicon"
                    aria-hidden="true"
                    data-testid="jazzicon"
                    v-html="getJazzicon(address, imageSize)"
                ></div>
            </template>
        </template>
        <template #suffix>
            <FCopyButton v-if="useCopyButton" :text="address" data-testcode="copy_button" />
            <slot name="suffix"></slot>
        </template>
    </FEllipsis>
</template>

<style lang="scss">
.faddress {
    --faddress-image-size: 24px;
    --faddress-gap: var(--f-spacer-2, 6px);

    &_image {
        margin-inline-end: var(--faddress-gap);
    }

    &_jazzicon {
        & > div:first-child {
            border-radius: 50%;
            display: block !important;
        }
    }

    &_address {
        display: flex;
        flex-direction: column;
    }

    .fimage {
        border-radius: 50%;
        overflow: hidden;
        min-width: var(--faddress-image-size);
    }

    &.f-ellipsis {
        display: flex;
        align-items: center;
        //gap: var(--faddress-gap);
    }

    .fellipsis_suffix {
        margin-inline-start: var(--faddress-gap);
    }
}
</style>
