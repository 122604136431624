<script>
import FInput from '@/components/core/FInput/FInput.vue';
import FActionButtons from '@/components/core/FActionButtons/FActionButtons.vue';

export default {
    name: 'TokenAmountField',

    components: { FActionButtons, FInput },

    props: {
        value: {
            type: [String, Number, Boolean, Object, Date],
            default: '',
        },
        /** Percentages separated by commas. Eg. '25%, 50%, 100%' */
        amountButtons: {
            type: String,
            default: '',
        },
        amountButtonsDecimals: {
            type: Number,
            default: -1,
        },
        maxAmount: {
            type: [Function, Number],
            default: 0,
        },
        showAmountButtons: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            val: '',
        };
    },

    computed: {
        cAmountButtons() {
            const percentages = this.amountButtons
                .split(',')
                .map((value) => parseInt(value))
                .filter((value) => !isNaN(value) && value >= 0 && value <= 100);

            return percentages.map((p) => {
                let label = `${p}%`;

                if (p === 100) {
                    label = 'Max';
                }

                return {
                    action: `amount_${p}`,
                    label,
                    // size: buttonSize.value,
                    // quaternary: true,
                };
            });
        },
    },

    watch: {
        value: {
            handler(val) {
                this.val = val;
            },
            immediate: true,
        },
    },

    methods: {
        getMaxAmount() {
            if (typeof this.maxAmount === 'function') {
                return this.maxAmount();
            }

            return this.maxAmount;
        },

        getAmount(percentage = 0) {
            let amount = 0;

            if (!isNaN(percentage) && percentage > 0) {
                const maxAmount = this.getMaxAmount();

                if (percentage < 100) {
                    amount = maxAmount * (percentage / 100);
                } else {
                    amount = maxAmount;
                }
            }

            return amount;
        },

        onAmountButtonAction(action) {
            const percentage = parseInt(action.split('_')[1]);

            if (!isNaN(percentage)) {
                if (this.amountButtonsDecimals > -1) {
                    this.val = Number(this.getAmount(percentage)).toFixed(5);
                } else {
                    this.val = this.getAmount(percentage);
                }
            }
        },
    },
};
</script>

<template>
    <FInput v-bind="$attrs" :value="val" :disabled="disabled" @input="$emit('input', $event)">
        <!-- copy slots -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
        <template #bottom2>
            <FActionButtons
                v-if="showAmountButtons"
                class="tokenamountfield_amountbuttons"
                btn-class="light light-accent small"
                :buttons="cAmountButtons"
                :disabled="disabled"
                @button-action="onAmountButtonAction"
            />
        </template>
    </FInput>
</template>

<style lang="scss">
.tokenamountfield {
    &_amountbuttons {
        padding-top: var(--f-spacer-1);
        //gap: var(--f-spacer-1);

        > * {
            flex: 1;
        }
    }
}
</style>
