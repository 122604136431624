<script>
import FForm from '@/components/core/FForm/FForm.vue';
import { WATCH_ACCOUNT } from '@/store/actions.type.js';
import FMessage from '@/components/core/FMessage/FMessage.vue';
import FInput from '@/components/core/FInput/FInput.vue';
import FButton from '@/components/core/FButton/FButton.vue';
import { mapGetters } from 'vuex';
import { nextTick } from 'vue';

export default {
    name: 'WatchAccountForm',

    components: { FButton, FInput, FMessage, FForm },

    data() {
        return {
            dErrorMsg: '',
            dShowErr: false,
        };
    },

    computed: {
        ...mapGetters(['getAccountByAddress']),
    },

    methods: {
        checkAddress(value) {
            this.dErrorMsg = 'Enter a valid Opera FTM address';
            this.dShowErr = false;
            return this.$fWallet.isValidAddress(value);
        },

        /**
         * @param {{detail: {data: {}}}} _event
         */
        async onFormSubmit(_event) {
            const { data } = _event.detail;
            const { address } = data;

            if (this.checkAddress(address)) {
                if (this.getAccountByAddress(address, 'watch')) {
                    this.dErrorMsg = 'A wallet with this address already exists';
                    this.dShowErr = true;
                } else {
                    await this.$store.dispatch(WATCH_ACCOUNT, address);

                    this.$emit('watch-account-form-data', { address });

                    nextTick(() => {
                        this.$router.push({ name: 'account-history', params: { address } });
                    });
                }
            }
        },

        onCancelButtonClick() {
            this.$emit('window-hide');
        },
    },
};
</script>

<template>
    <div class="watch-account-form">
        <FForm center-form @f-form-submit="onFormSubmit">
            <FInput
                type="text"
                label="Address"
                field-size="large"
                name="address"
                validate-on-input
                :validator="checkAddress"
            >
                <template #bottom="sProps">
                    <FMessage v-show="sProps.showErrorMessage || dShowErr" type="error" alert with-icon>
                        {{ dErrorMsg }}
                    </FMessage>
                </template>
            </FInput>

            <div class="align-center form-buttons">
                <FButton label="Cancel" class="btn large secondary" @click.native="onCancelButtonClick" />
                <FButton type="submit" label="Submit" class="btn large" />
            </div>
        </FForm>
    </div>
</template>

<style lang="scss">
.watch-account-form {
    .form-buttons {
        margin-top: var(--f-spacer-4);
    }
}
</style>
