<script>
import FButton from '@/components/core/FButton/FButton.vue';
import CreateAccountWindow from '@/components/windows/CreateAccountWindow/CreateAccountWindow.vue';
import ConnectWalletWindow from '@/components/windows/ConnectWalletWindow/ConnectWalletWindow.vue';
import RestoreAccountWindow from '@/components/windows/RestoreAccountWindow/RestoreAccountWindow.vue';
import WatchAccountWindow from '@/components/windows/WatchAccountWindow/WatchAccountWindow.vue';

export default {
    name: 'WalletActions',

    components: { WatchAccountWindow, RestoreAccountWindow, ConnectWalletWindow, CreateAccountWindow, FButton },

    methods: {
        onConnectWalletClick() {
            this.$refs.connectWalletWindow.show();
            this.$emit('wallet-action', 'connect');
        },

        onCreateAWalletClick() {
            this.$refs.createAccountWindow.show();
            this.$emit('wallet-action', 'create');
        },

        onRestoreWalletClick() {
            this.$refs.restoreAccountWindow.show();
            this.$emit('wallet-action', 'restore');
        },

        onWatchWalletClick() {
            this.$refs.watchAccountWindow.show();
            this.$emit('wallet-action', 'watch');
        },

        onWindowHide() {
            this.$emit('window-hide');
        },
    },
};
</script>

<template>
    <div class="walletactions">
        <ul class="no-markers">
            <li>
                <FButton class="btn menu-btn large light" title="Connect Wallet" @click.native="onConnectWalletClick">
                    <icon data="@/assets/svg/connect.svg" width="20" height="20" aria-hidden="true" />
                    Connect
                </FButton>
            </li>
            <li>
                <FButton class="btn menu-btn large light" title="Create Wallet" @click.native="onCreateAWalletClick">
                    <icon data="@/assets/svg/wallet.svg" width="20" height="20" :fill="false" aria-hidden="true" />
                    Create
                </FButton>
            </li>
            <li>
                <FButton class="btn menu-btn large light" title="Restore Wallet" @click.native="onRestoreWalletClick">
                    <icon data="@/assets/svg/key.svg" width="20" height="20" aria-hidden="true" />
                    Restore
                </FButton>
            </li>
            <li>
                <FButton class="btn menu-btn large light" title="Watch Wallet" @click.native="onWatchWalletClick">
                    <icon data="@/assets/svg/eye.svg" width="20" height="20" aria-hidden="true" />
                    Watch
                </FButton>
            </li>
        </ul>

        <ConnectWalletWindow ref="connectWalletWindow" @window-hide="onWindowHide" />
        <CreateAccountWindow ref="createAccountWindow" @window-hide="onWindowHide" />
        <RestoreAccountWindow ref="restoreAccountWindow" @window-hide="onWindowHide" />
        <WatchAccountWindow ref="watchAccountWindow" @window-hide="onWindowHide" />
    </div>
</template>

<style lang="scss">
.walletactions {
    ul {
        //display: flex;
        //justify-content: space-between;
        li {
            display: block;
        }
    }

    /*
    .btn {
        svg {
            margin-inline-end: var(--f-spacer-1);
        }
    }
    */
}
</style>
