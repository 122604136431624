<script>
import WalletButtonC from '@/components/WalletButtonC/WalletButtonC.vue';

export default {
    name: 'WalletHeader',
    components: { WalletButtonC },
};
</script>

<template>
    <header class="walletheader">
        <WalletButtonC />
    </header>
</template>

<style lang="scss">
.walletheader {
    padding-top: var(--f-spacer-4);
    text-align: end;
}
</style>
