<script>
import WalletButton from '@/components/WalletButton/WalletButton.vue';
import WalletButtonPopover from '@/components/WalletButtonPopover/WalletButtonPopover.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'WalletButtonC',

    components: { WalletButtonPopover, WalletButton },

    props: {},

    data() {
        return {
            id: 'wallet-button',
        };
    },

    computed: {
        ...mapGetters(['currentAccount']),

        cAddress() {
            return this.currentAccount?.address || '';
        },

        cName() {
            return this.currentAccount?.name || '';
        },

        cType() {
            return this.currentAccount?.type || '';
        },

        cAccountTypeName() {
            return this.cAddress ? this.$fWallet.getAccountTypeName(this.currentAccount) : '';
        },
    },

    watch: {
        $route() {
            this.$refs.popover.hide();
        },
    },

    methods: {
        onClick() {
            this.$refs.popover.show();
        },
    },
};
</script>

<template>
    <div>
        <WalletButton
            :id="id"
            :address="cAddress"
            :name="cName"
            :type="cType"
            :sub-text="cAccountTypeName"
            placeholder-text="Connect Wallet"
            v-bind="$attrs"
            @click.native="onClick"
        />
        <WalletButtonPopover ref="popover" :attach-to="`#${id}`">
            <slot name="popover"></slot>
        </WalletButtonPopover>
    </div>
</template>

<style scoped lang="scss"></style>
